.add-request-for-purchase {
  padding: 20px;
  background-color: var(--whitesmoke);
  .breadcrumb-container {
      display: flex;
      align-items: center;
      padding: 10px ;
      font-size: 16px;
      color: var(--grey);
     
    background-color: var(--white);
  
    .breadcrumb-icon {
      color: var(--grey);
      margin-right: 8px;
    }
  
    .breadcrumb-text {
      margin: 0 5px;
      text-decoration: none; // Remove underline
      color: inherit;
  &:hover {
    text-decoration: none; // Ensure no underline on hover
   
  }
    }
  
    .breadcrumb-separator {
      margin: 0 5px;
      color: var(--fade-grey);
    }
    }
  .request-purchase-list-container{

    padding:5px;
    border-radius: 10px;

    background-color: var(--whitesmoke);
  
}
  .add-request-page {
     padding:20px;
     background-color: var(--white);
 
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  
  }
  .add-request-header-container{
    border: 1px solid var(--border);
      padding:10px;
      border-radius: 10px;


  .add-request-header {
    font-weight: bold;
   
    margin-bottom: 20px;
  }

  .add-request-form{
     

  .form-row {
 
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 15px;
    
  }

  .form-group {
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  .inline-supplier {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start  !important;
    gap: 10px;

    .radiobox{
      align-items: center !important;
      justify-content: center !important;
      margin-top: 10px;
    }

    label {
     align-items: center;
      margin-bottom: 0; /* Ensure label is aligned properly */
    }
  }

  .po-number-group {
    display: flex;
    flex-direction: column;

    .po-number-editable {
      display: flex;
      align-items: center;

      .po-input {
        border: none;
        border-bottom: 1px solid var(--border-bottom);
        width: 100%;
        padding-right: 20px;
        padding: 4px;
      }

      
    }
  }
.date-input{
  margin:0px;
}
  .form-control {
   
   
    border: 1px solid var(--border);
    border-radius: 4px;
    margin-top: 1px;
  }

  .toggle-label {
    cursor: pointer;
    color:black;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-editor-container {
  

    .toolbar {
      display: flex;
      gap: 10px;
      margin-bottom: 5px;

      button {
        background: none;
        border: none;
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .text-editor {
      padding: 10px;
      border: 1px solid var(--border);
      border-radius: 4px;
      resize: none;
      width: 100%;
    }
  }

  .radio-group {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .payment-type {
    display: flex;
    flex-direction: row;
    gap:10px;
    
    .radio-group {
      display: flex;
      gap: 20px;
      align-items: center !important;
      
    
    
    .radio-option {
      display: flex;
      align-items: center !important;
      gap: 8px;
     
    
    
    .radio-label {
      align-items: center ;
     
      color: var(--grey);
    }
  }
}
    
  
  }

  .editable-checkbox {
    display: flex;
    align-items: flex-start;

    label {
      margin-left: 8px;
      font-size: 16px;
    }
  }
  }
}
.raw-material-details {
  background-color: var(--white);
  border: 1px solid var(--border);
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;     
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-bottom);

    .add-request-header1 {
      font-weight: bold;
      margin: 0;
    
    }
    
      .add-new-button {
        display: flex;
        align-items: center;
        background-color: var(--dark-blue);
        color: var(--white);
        border-radius: 4px;
        padding:4px;
       
      }
    
     
    
  }

  .table-bordered {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    padding: 5px;
  
    th,
    td {
      padding: 5px;
      border-bottom: 1px solid var(--border-bottom);
    }
  
    th {
      background-color: var(--th);
      text-align: left;
    }
  
    .table-input {
      border: none;
      border-bottom: 1px solid var(--border-bottom);
      width: 100%;
    }
  
    .empty {
      background-color: #f1f1f1;
    }
}   
  .button-container {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
   


  
  .save-button {
    background-color: var(--dark-blue);
    color: var(--white);
    padding: 7px;
  border-radius: 4px;
  }
}
}
// Media queries for responsiveness using min-widths
@media (max-width: 1400px) {
  .form-row {
    flex-direction: row !important;
  }
  .form-group {
    width: 48%;
  }
}

@media (max-width: 1200px) {
  .form-row {
    flex-direction: row !important;
  }
  .form-group {
    width: 48%;
  }
}

@media (max-width: 992px) {
  .form-row {
    flex-direction: row !important;
  }
  .form-group {
    width: 48%;
  }
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column !important;
  }
  .form-group {
    width: 100% !important;
  }
  .table-bordered {
    display: block;
       width: 100%; /* Ensure the table container spans the full width */
       overflow-x: auto; /* Allow horizontal scrolling */
       white-space: nowrap; /* Prevent table contents from wrapping */
   
   /* Ensure the table doesn't shrink for all screen sizes */
   table {
       table-layout: fixed; /* Prevent columns from resizing */
       width: 100%; /* Maintain table size */
   }
}
}

@media (max-width: 576px) {
  .form-row {
    flex-direction: column !important;
  }
  .form-group {
    width: 100% !important;
  }
  .table-bordered {
    display: block;
       width: 100%; /* Ensure the table container spans the full width */
       overflow-x: auto; /* Allow horizontal scrolling */
       white-space: nowrap; /* Prevent table contents from wrapping */
   
   /* Ensure the table doesn't shrink for all screen sizes */
   table {
       table-layout: fixed; /* Prevent columns from resizing */
       width: 100%; /* Maintain table size */
   }
}
}

// Apply column layout for screens 575.98px and below
// Responsive styles for smaller screens
@media (max-width: 575.98px) {
  .header {
    flex-direction: row !important; /* Keep text and button in a row */
    justify-content: space-between; /* Ensure proper spacing between text and button */
    align-items: center; /* Center align content vertically */
    overflow: hidden; /* Prevent overflow */
  }
  
  .payment-type {
    flex-direction: column; /* Stack the radio options vertically */
    align-items: center; /* Center align the radio group */

    .radio-group {
      flex-direction: column; /* Stack individual radio options vertically */
      gap: 10px;

      .radio-option {
        flex-direction: column; /* Move label below the radio button */
        align-items: center;
        gap: 5px; /* Adjust spacing between the radio button and label */

        .radio-label {
          text-align: center; /* Center the label text */
        }
      }
    }
  }

  .add-request-header1 {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .add-new-button {
    padding: 6px 12px; /* Increase padding slightly for better appearance */
    font-size: 14px; /* Adjust font size */
  }

  .form-row {
    flex-direction: column !important;
  }

  .form-group {
    width: 100% !important;
  }

  .table-bordered {
    display: block;
       width: 100%; /* Ensure the table container spans the full width */
       overflow-x: auto; /* Allow horizontal scrolling */
       white-space: nowrap; /* Prevent table contents from wrapping */
   
   /* Ensure the table doesn't shrink for all screen sizes */
   table {
       table-layout: fixed; /* Prevent columns from resizing */
       width: 100%; /* Maintain table size */
   }
}
}

}