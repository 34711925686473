.footer {
  background-color: #e6f2ff;
  padding: 20px;
  overflow-x: auto;
  color: rgb(80, 124, 144);
  position: relative; /* Make sure footer is relatively positioned */

  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
  .footer-social ul li {
    cursor: pointer; /* Ensures the pointer cursor on hover */
    user-select: text; /* Allows text selection */
    transition: color 0.3s ease-in-out; /* Smooth color transition */
  }

  &-column {
    flex: 1;
    margin: 20px;
    min-width: 200px;

    .footer-heading {
      font-weight: 700%;
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 5px;
      }
    }

    .social-icons {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;

      i {
        font-size: 24px;
        color: rgb(80, 124, 144);
      }
    }

    .subscribe-input {
      display: flex;
      margin-top: 10px;

      input {
        padding: 10px;
        border: 1px solid #ddd;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        flex: 1;
        width: calc(100% - 100px); /* Adjust width to leave space for the button */
      }

      button {
        background-color:var(--dark-blue);
        border-top-right-radius: 10px; /* Rounded top-right corner */
         border-bottom-right-radius: 10px; /* Rounded bottom-right corner */
        color: white;
        padding: 10px;
        border: none;
        width: 100px; /* Fixed width for the button */
      }
    }

    &-truemed {
      width: 500px;
    }

    .googleplay {
      margin-top: 10px;
      width: 150px;
    }
  }

  &-divider {
    border: none;
    border-top: 3px solid white;
    margin: 20px 0;
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    flex-wrap: wrap; /* Allows items to wrap to next line if necessary */

    .footer-copyright-text {
      margin: 0;
      color: #5a7d9a;
      text-align: center; /* Center-align text by default */
    }

    .footer-payment-partners {
      display: flex;
      align-items: center;
      gap: 10px;
      text-align: center; /* Center-align text by default */

      .footer-payment-partners-text {
        margin-right: 10px;
        color: #5a7d9a;
      }

      i {
        color: #5a7d9a;
      }
    }
  }
}
  /* Media Queries */
  
  /* For extra small devices (max-width: 575.98px) */
  
/* For extra small devices (max-width: 575.98px) */
/* For extra small devices (max-width: 575.98px) */
/* For extra small devices (max-width: 575.98px) */
@media (max-width: 575.98px) {
    .footer-container {
      display: block; /* Stack as column */
      padding-bottom: 60px;
  
    .footer-bottom {
      display: flex; /* Use Flexbox to control order and centering */
      flex-direction: column; /* Stack as column */
      align-items: center; /* Center-align items horizontally */
      justify-content: center; /* Center-align items vertically */
      text-align: center; /* Center-align text */
  
      .footer-payment-partners {
        order: 1; /* Display on top */
        display: block; /* Stack as column */
        text-align: center; /* Center-align text */
        margin-bottom: 10px; /* Add space below */
      }
  
      .footer-copyright-text {
        order: 2; /* Display below footer-payment-partners */
        margin: 0; /* Reset any margin */
        text-align: center; /* Center-align text */
      }
    }
  }
  
}

  
  /* For small devices (min-width: 576px and max-width: 767.98px) */
  @media (min-width: 576px) {
    .footer-container {
        display: block; /* Stack as column */
        padding-bottom: 60px;
       
      
    
      .footer-bottom {
        display: flex;
        justify-content: space-between; /* Align footer-bottom to the left and footer-payment-partners to the right */
        align-items: center;
        width: 100%; /* Ensure it takes the full width */
        .footer-payment-partners {
            display: block; /* Stack as column */
            text-align: center; /* Center-align text */
            margin-bottom: 10px; /* Add space below */
          }
    }
  }
}
  
  /* For medium devices (min-width: 768px and max-width: 991.98px) */
  @media (min-width: 768px)  {
    .footer-container {
        display: block; /* Stack as column */
        padding-bottom: 60px;
       
      
    
      .footer-bottom {
        display: flex;
        justify-content: space-between; /* Align footer-bottom to the left and footer-payment-partners to the right */
        align-items: center;
        width: 100%; /* Ensure it takes the full width */
      }
  }
}
  
  
/* For larger devices (min-width: 992px) and above */
@media (min-width: 992px) {
    .footer-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  
    
  
      .footer-subscribe {
        flex: 2; /* Give more space to the subscribe section */
      }
    
  
    .footer-bottom {
      display: flex;
      justify-content: space-between; /* Align footer-bottom to the left and footer-payment-partners to the right */
      align-items: center;
      width: 100%; /* Ensure it takes the full width */
    }
  }
}
  
  /* For extra large devices (min-width: 1200px) */
  @media (min-width: 1200px) {
    .footer-container {
        display: flex;
        justify-content: space-between;
    padding-bottom: 0px !important;
    
        .footer-subscribe {
          flex: 2; /* Give more space to the subscribe section */
        }
    
        .footer-truemed {
          flex: 2;
        }
      
    
      .footer-bottom {
        display: flex;
        justify-content: space-between; /* Align footer-bottom to the left and footer-payment-partners to the right */
        align-items: center;
        width: 100%;
      }
    }
  }
  
  /* For extra extra large devices (min-width: 1400px) */
  @media (min-width: 1400px) {
    .footer-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0px !important;
  
      .footer-subscribe {
        flex: 2; /* Give more space to the subscribe section */
      }
  
      .footer-truemed {
        flex: 2;
      }
    
  
    .footer-bottom {
      display: flex;
      justify-content: space-between; /* Align footer-bottom to the left and footer-payment-partners to the right */
      align-items: center;
      width: 100%;
    }
  }
}