.card-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically center content */
  padding: 16px;
  margin: 15px 0;
  border: 1px solid var(--border);
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  min-height: 120px;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add hover effect */
  }
}

.card-img-class {
  flex: 0 0 60px; /* Fixed width for the image */
  margin-right: 20px;

  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: contain; /* Ensure image scales well */
  }
}

.card-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space between title, company, size, and price */
  padding-left: 15px;

  .card-title {
    font-weight: bold;
    color: var(--grey);
    margin-bottom: 5px;
  }

  .card-company,
  .card-size {
    color: var(--light-grey);
    margin-bottom: 5px;
  }

  .card-price {
    font-weight: bold;
    color: var(--dark-blue);

    .text-muted {
      color: var(--fade-grey);
      text-decoration: line-through;
      margin-left: 5px;
    }

    .discount-text {
      color: var(--green);
      margin-left: 5px;
    }
  }
}

.card-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  .add-to-cart-btn {
    display: inline-block;
    padding: 8px;
    width: 120px;
    font-weight: 600;
    color: var(--dark-blue);
    border: 2px solid var(--dark-blue);
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--dark-blue);
      color: var(--white);
      border-color: var(--dark-blue);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px var(--dark-blue);
    }
  }

  .card-quantity-display {
    display: inline-block;
    background-color: #f8f9fa;
    color: #007bff;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #007bff;
    border-radius: 20px;
    padding: 5px 15px;
    text-align: center;
    white-space: nowrap;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, color 0.3s ease;
  }
}

.card-icons {
  display: flex;
  gap: 10px;

  .pause-button,
  .close-button5 {
    padding: 5px 15px;
    border: 2px solid;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .pause-button {
    background-color: var(--yellow);
    color: var(--white);
    border-color: var(--yellow);

    &:hover {
      background-color: var(--dark-yellow);
    }
  }

  .close-button5 {
    background-color: var(--red);
    color: var(--white);
    border-color: var(--red);

    &:hover {
      background-color: var(--dark-red);
    }
  }

  .disabled-card {
    pointer-events: none; // Disable all interactions
    opacity: 0.5; // Make it look disabled
  }
  
  .out-of-stock-overlay {
    position: relative;
  }
  
  .out-of-stock-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background: red;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .out-of-stock-button {
    background-color: gray;
    color: white;
    cursor: not-allowed;
  }
  
  .out-of-stock-card {
    opacity: 0.6;
    pointer-events: none;
  }
  
  .out-of-stock-overlay {
    position: relative;
  }
  
  .out-of-stock-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background: red;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .out-of-stock-button {
    background-color: gray;
    color: white;
    cursor: not-allowed;
  }
  
}


@media (max-width: 578px) {
  .card-main-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
  }

  .card-img-class {
    margin-bottom: 15px;

    img {
      width: 100px;
      height: auto;
    }
  }

  .card-details {
    text-align: center;
    padding: 0;

    .card-title {
      font-size: 16px;
    }

    .card-price {
      font-size: 14px;

      .text-muted {
        font-size: 12px;
      }
    }
  }

  .card-right {
    align-items: center;
  }
}
