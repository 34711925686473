.admin-login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
  
    .admin-login-logo {
      margin-bottom: 20px;
      img {
        width: 100px;
        height: auto;
      }
    }
  
    .admin-login-box {
      display: flex;
      width: 70%;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      overflow: hidden;
  
      .admin-login-left {
      
        flex: 1;
        background-color: var(--dark-blue);
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
  
        img.login-doc-image {
          position: absolute;
          bottom: 0;
          left: 0;
          width:80%;
          height: 90%;
          margin-left: 20px;
          margin-bottom: 20px;
          object-fit: contain; 
        }
      }
  
      .admin-login-right {
        flex: 1;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        .sign-in-header {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 8px;
        }
  
        .access-account {
          font-size: 16px;
          margin-bottom: 20px;
        }
  
        .form-group {
          margin-bottom: 15px;
  
          .login-input {
            width: 100%;
            padding: 12px 15px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
          }
        }
  
        .login-btn {
          width: 100%; // Set button width to 100%
          background-color: var(--dark-blue);
          color: white;
         
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          margin-top: 10px;
          text-align: center;
  
          &:hover {
            background-color: var(--dark-blue);
          }
        }
  
        .or-divider {
          text-align: center;
          margin: 15px 0;
          color: #999;
        }
  
        .google-login-btn {
          width: 100%; // Set button width to 100%
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          border: 1px solid #ccc;
         
          border-radius: 4px;
          cursor: pointer;
          color: #333;
         
          font-weight: 500;
  
          &:hover {
            background-color: #f1f1f1;
          }
  
          i {
            margin-right: 8px;
            font-size: 18px;
          }
  
          .google-icon {
            width: 20px;
            height: auto;
            margin-right: 8px;
          }
        }
  
        .no-account {
          text-align: center;
          margin-top: 20px;
          color: black;
  
          a {
            color: var(--dark-blue);
            text-decoration: none;
  
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
    /* Media queries for different screen sizes */
    @media (min-width: 1400px) {
        .admin-login-box {
          flex-direction: row !important; /* Row layout for screens 1400px and above */
        }
      }
    
      @media (min-width: 1200px) {
        .admin-login-box {
          flex-direction: row !important; /* Row layout for screens between 1200px and 1399.98px */
        }
      }
    
      @media (min-width: 992px)  {
        .admin-login-box {
            overflow: hidden;
            width:100%;
          flex-direction: column; /* Column layout for screens between 992px and 1199.98px */
        }
    
        .admin-login-left {
         display: none; /* Center align left content for smaller screens */
        }
    
        .admin-login-right {
          padding-top: 20px; /* Add some padding on top for spacing */
        }
      }
    
      @media (min-width: 768px) {
        .admin-login-box {
          width: 100%;
          flex-direction: column;
        }
    
        .admin-login-left {
          display: none; /* Hide left section */
        }
    
        .admin-login-right {
          padding-top: 20px;
        }
      }
    
      @media (min-width: 576px) {
        .admin-login-box {
          width: 100%;
          height: auto;
          flex-direction: column;
        }
    
        .admin-login-left {
          display: none; /* Hide left section */
        }
        
    
        .admin-login-right {
          padding-top: 20px;
        }
      }
    
      @media (max-width: 575.98px) {
        .admin-login-box {
          width: 100% !important;
          flex-direction: column;
        }
    
        .admin-login-left {
          display: none; /* Hide left section */
        }
    
        .admin-login-right {
          padding-top: 20px;
        }
      }
    