.business-info-section {
  padding: 20px;
  background-color: whitesmoke;
  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 10px ;
    font-size: 16px;
    color: #555;
   
  background-color: var(--white);

  .breadcrumb-icon {
    color: #333;
    margin-right: 8px;
  }

  .breadcrumb-text {
    margin: 0 5px;
    text-decoration: none; // Remove underline
    color: inherit;
&:hover {
  text-decoration: none; // Ensure no underline on hover
 
}
  }

  .breadcrumb-separator {
    margin: 0 5px;
    color: #aaa;
  }
  }
  
  
  .business-info-container {
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    .business-info-heading {
      margin-bottom: 20px;
    }

    .form-row {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 15px;
      flex-wrap: nowrap; /* Default row layout */
    }

    .form-group {
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        margin-bottom: 8px;
      }

      input {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }

    .save-button-container {
      display: flex;
      justify-content: flex-end;
    }

    .save-button {
      background-color: var(--dark-blue);
      color: white;
      border: none;
      border-radius: 4px;
      padding: 7px;
      cursor: pointer;
    }
  }
}

@media (min-width: 1400px),
(min-width: 1200px),
(min-width: 992px),
(min-width: 768px),
(min-width: 576px) {
  .business-info-container {
    .form-row {
      display: flex;
      flex-direction: row; /* Keep the fields in a row */
      gap: 10px;
      flex-wrap: nowrap;
    }
  }
}

@media (max-width: 575.98px) {
 
  .business-info-container {
    .form-row {
      display: block;
      flex-direction: column; /* Stack the fields in a column */
    }

    .form-group {
      margin-bottom: 15px;
    }

    .save-button-container {
      justify-content: center; /* Center the save button on small screens */
    }
  }
}
