.bill-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    color: var(--grey);
    font-size: small;
  
    .bill-item-label {
      font-weight: 600;
    }
    .bill-item-value.bill-amount {
      margin: 0; /* Removes unnecessary margin */
      font-weight: bold; /* Ensures the amount is visually distinct */
      color: var(--dark-blue);
    }
    .bill-item-label-bold {
      font-weight: bold; /* Make the Grand Total label bold */
    }
    
  
    .bill-item-value {
      font-weight: 400;
  
      &.bill-item-discount {
        color: #28a745;
        font-weight: 600;
      }
  
      .bill-item-striked {
        color: #6c757d;
        text-decoration: line-through;
      }
    }
  }
  
  .bill-item-small {
    font-size: 12px; /* Smaller font size */
  }
  