.react-datepicker-wrapper {
    width: 100%;
    position: relative;
  
  
    .react-datepicker__input-container {
      input {
        width: 100%;
        padding: 8px;
        border-radius: 5px;
        border: 1px solid var(--border);
        font-size: 14px;
        color: var(--text-color);
  
        &:focus {
          border-color: var(--dark-blue);
          outline: none;
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }
  
        &::placeholder {
          color: var(--placeholder-color);
        }
      }
    }
  
    .react-datepicker {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      background-color: var(--white);
      border: 1px solid var(--border);
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
      .react-datepicker__header {
        background-color: var(--light-grey);
        border-bottom: 1px solid var(--border);
        color: var(--text-color);
      }
  
      .react-datepicker__day,
      .react-datepicker__day-name {
        width: 40px;
        line-height: 40px;
        text-align: center;
        margin: 2px;
        border-radius: 50%;
        cursor: pointer;
  
        &:hover {
          background-color: var(--hover-grey);
        }
      }
  
      .react-datepicker__day--selected {
        background-color: var(--dark-blue);
        color: var(--white);
  
        &:hover {
          background-color: var(--hover-blue);
        }
      }
  
      .react-datepicker__day--today {
        font-weight: bold;
        color: var(--dark-blue);
      }
    }
  }
  