.location-main-page {
  background-color: var(--whitesmoke);;
  padding:20px;

  
  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 10px;
    color: var(--grey);
   
  background-color: var(--white);

  .breadcrumb-icon {
    color: var(--grey);
    margin-right: 8px;
  }

  .breadcrumb-text {
    margin: 0 5px;
    text-decoration: none; // Remove underline
    color: inherit;
&:hover {
  text-decoration: none; // Ensure no underline on hover
 
}
  }

  .breadcrumb-separator {
    margin: 0 5px;
    color: var(--fade-grey);
  }
  }


.location-container{

  padding:5px;
  border-radius: 10px;

  background-color: var(--whitesmoke);

}
.location-page-section {
  padding: 20px ;
  background-color: var(--white);
  border-radius: 10px;

  .text-heading1 {
    padding: 10px !important;
    margin-bottom: 20px;
    text-align: left !important;
    align-self: flex-start; /* If parent is flexbox */
}
    .form-row {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
    
  
    .form-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      
      label {
        margin-bottom: 5px;
        font-weight: bold;
      }
  
      input, select {
       
        border: 1px solid var(--border);
        border-radius: 4px;
      }
    }
  }
  
    .action-buttons {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;

     

      .save-button {
        background-color: var(--dark-blue);
        color: var(--white);
        border: none;
        border-radius: 4px;
        cursor: pointer;
        padding: 10px ;


        &:hover {
          background-color: #083d73;
        }
      }
    }


  
    /* Media Queries for Responsive Design */
    @media (min-width: 1400px) {
      .location-page-section{
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
    }
    @media (min-width: 1200px) {
      .location-page-section{
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  }
  
    @media (min-width: 992px) {
      .location-page-section{
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  }
  
    @media (min-width: 768px) {
      .location-page-section{
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  }
  
    @media (min-width: 576px) {
      .location-page-section{
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
    }
    /* Stack fields in a column for small screens */
    @media (max-width: 575.98px) {
    
      
      .form-row {
        display: flex;
        flex-direction: column;
      
  
      .form-group {
        margin-bottom: 15px;
      }
     
    }
    .action-buttons {
      width: 100%; /* Ensures the action buttons container spans the full width */
      display: flex; /* Flexbox to align the button */
      justify-content: center; /* Centers the button within the container */
    
      .save-button {
        background-color: var(--dark-blue);
        color: var(--white);
        border: none;
        border-radius: 4px;
        cursor: pointer;
        padding: 6px;
        width: 100%; /* Makes the button span the full width of the parent */
    
        &:hover {
          background-color: #083d73;
        }
      }
    }
    
    }
  
}}