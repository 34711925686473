.add-delivery-person-section {
  padding: 20px;
  background-color: var(--whitesmoke);
  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 10px;
    color: var(--grey);
   
  background-color: var(--white);

  .breadcrumb-icon {
    color: var(--grey);
    margin-right: 8px;
  }

  .breadcrumb-text {
    margin: 0 5px;
    text-decoration: none; // Remove underline
    color: inherit;
&:hover {
  text-decoration: none; // Ensure no underline on hover
 
}
  }

  .breadcrumb-separator {
    margin: 0 5px;
    color: var(--fade-grey);
  }
  }
  .add-deliververy-person-container{
    padding:5px;
    border-radius: 10px;

  }
  .add-delivery-person {
    padding: 20px ;
    background-color: var(--white) !important;
    border-radius: 10px;

    .text-heading1 {
      padding: 10px !important;
      margin-bottom: 20px;
      text-align: left !important;
      align-self: flex-start; /* If parent is flexbox */
  }
  
    .form-row {
      
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
    

    .form-group {
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        margin-bottom: 5px;
        font-weight: bold;
      }
  
     
        .required-symbol {
          color: var(--red);
          margin-left: 5px;
        }
    }
  }
  .form-row{
  .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      margin-bottom: 5px;
      font-weight: bold;
    }
    .image-container {
      position: relative;
      display: inline-block;
      width: 200px; /* Adjust to fit the size shown in your image */
      height: 100px;
      border: 1px dashed var(--border); /* Border around the container */
      border-radius: 8px;
      overflow: hidden;
    
      .uploaded-image {
        width:200px;
        height: 100px;
        object-fit: cover; /* Ensures the image fills the container */
        border-radius: 8px;
      }
    
      .close-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 16px;
        color: var(--white);
        background-color: var(--red); /* Red background for the close icon */
        border-radius: 50%;
        padding: 4px;
        cursor: pointer;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Adds shadow for a floating effect */
      }
    }
   
      .required-symbol {
        color: var(--red);
        margin-left: 5px;
      }
  
}
}

  
      .action-buttons {
          display: flex;
          justify-content: flex-end;
          padding: 10px;
        
        
        .save-button {
          background-color: var(--dark-blue);
          color: var(--white);
        }
      }  
    
    }
   /* Media Queries for Responsive Design */
   
   

  @media (min-width: 1400px) {
   
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      
    }
    }
  
    @media (min-width: 1200px) {
     
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    
    }
  
    @media (min-width: 992px) {
   
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    
  }
  
    @media (min-width: 768px) {
      
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    
  }
  
    @media (min-width: 576px) {
    
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      
    }
    }
  
    /* Stack fields in a column for small screens */
    @media (max-width: 575.98px) {
   
      .form-row {
        display: flex;
        flex-direction: column;
      
  
      .form-group {
        margin-bottom: 15px;
      }
    }
      .form-row1{
        width:100% !important;
        .form-group {
          margin-bottom: 15px;
          width:100%;
        }

      }
      .save-button {
        width: 100%;
       
      }
    }
  }


