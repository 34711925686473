.primaryNavbar {
  position: fixed; /* Makes the navbar fixed */
  top: 0; /* Aligns it to the top of the page */
  left: 0; /* Aligns it to the left of the page */
  right: 0; /* Stretches it to the right */
  z-index: 1000; /* High z-index to keep it on top of other content */
  background-color: #f8f9fa; /* Background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  padding: 10px 20px; /* Adds some padding around the navbar content */

  .main-content {
    margin-top: 70px; /* This should match the height of the navbar */
  height: calc(100vh - 70px); /* Dynamically calculate height to fill remaining viewport space */
  overflow-y: auto; /* Allows vertical scrolling */
  padding: 20px;
  }
  .primaryNavbar-container {
    display: flex;
    align-items: center !important;
    justify-content: space-between;
  }

  .primaryNavbar-class {
    display: flex;
    align-items: center;
  }

  
    .menu-icon{
      border: none !important;
      background-color: transparent;
      margin-right: 15px;
    .navbar-toggler-icon {
      width: 25px;
      height: 25px;
      background-color: #ffffff;
      border:none;
    }
  
  }

  .primaryNavbarLogo {
    display: flex;
    align-items: center;

    .primaryNavbar-pharma-logo {
      width: 60px;
     
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .searchBar {
    flex: 1;
    margin: 0 15px;
  }

  .iconsGroup {
    display: flex;
    align-items: center;
    gap: 15px;

    .icon {
      font-size: 20px;
      color: #333;
      cursor: pointer;

      &:hover {
        color: #007bff;
      }
    }
   
  }

  .offcanvas {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures content is spaced correctly */

    .offcanvas-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid #f2f4f7;
    }

    .offcanvas-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .user-details-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(to right, #e0f7ff, #e5f9ff);
        padding: 10px;
        border-radius: 4px;

        .user-text {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .user-name {
            font-weight: bold;
            color: #333;
          }

          .user-number {
            color: #666;
          }

          .user-details {
            color: var(--dark-blue);
            text-decoration: none;
          }
        }

        .user-edit {
          color: var(--dark-blue);
          font-weight: bold;
          cursor: pointer;
          text-decoration: none;
          align-self: flex-start;
          margin-left: auto;
        }
      }

      .bottom-section {
       
        padding: 10px;
        border-radius: 4px;
        text-align: left;
        margin-top: 20px;
       

        .bottom-item {
          background-color: rgb(237, 237, 237);
          display: flex; /* Flexbox to align items in a row */
          align-items: center; /* Align items vertically centered */
          padding: 10px;
          cursor: pointer;
          border-bottom: 1px solid rgb(188, 186, 186);
          
        
          .icon-left {
            margin-right: 10px; /* Add space between the icon and text */
            color: var(--dark-blue); /* Icon color */
          }
        
          .item-text {
            flex-grow: 1; /* Allow text to take up remaining space */
            color: black; /* Text color */
          }
        
          .arrow-icon {
            font-size: 18px;
            color: var(--grey);
          }
        }
      }

      .terms-and-conditions,
      .logout {
        background-color: none;
        display: flex;
        justify-content: flex-start;
        padding: 10px;
        color: var(--light-grey);
        text-align: left;
        border-top: 1px solid #e0e0e0;
      }
    }

    /* Bottom Navigation Section */
    .bottom-navigation {
      position: fixed; /* Fix to the bottom */
      bottom: 0; /* Align at the bottom */
      left: 0;
      width: 100%; /* Full width */
      background-color: #ffffff;
      display: flex;
      justify-content: space-around;
      padding: 10px 0;
      border-top: 1px solid #e0e0e0;
      z-index: 10; /* Ensure it stays on top */
      
      .nav-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        cursor: pointer;

        .nav-icon {
          font-size: 18px;
          color: #6c757d;
        }

        .nav-text {
          font-size: 12px;
          color: #6c757d;
          margin-top: 2px;
        }
      }
    }
  }
  
    /* For screens between 567px and 767.98px */
    @media (max-width: 575.98px) {
    
      .primaryNavbar-toggle {
        padding:0px;
        display: block; /* Show toggle button */
        margin-right: 5px;
        justify-content: space-between;
    }
    
    .navbar-brand {
        margin-bottom: 10px; /* Add space below the logo */
    }
    .primaryNavbarLogo {
        order: 1;
        flex:1px;
        width: 50px;
        height: 40px;
        
    }
    
    .searchBar {
      
        order: 3;
        width: 100%; 
      
       
    }
    
    // .navbar-collapse {
    //     display: none; /* Hide IconsGroup */
    // }
    .iconsGroup{
        order:2;
        display: flex !important;
       
    }
    .offcanvas {
      display: block; // Show offcanvas menu
  }
  }
  @media (min-width: 576px) {
  .primaryNavbar-toggle {
    display: block; /* Show toggle button */
    margin-right: 5px;
    justify-content: space-between;
}

.navbar-brand {
    margin-bottom: 10px; /* Add space below the logo */
}
.primaryNavbarLogo {
    order: 1;
    flex:1px;
    
}

.searchBar {
    order: 3;
    width: 100%; /* Make search bar take full width */
    margin-top: 10px; /* Add some spacing */
    flex-basis: 100%; /* Push the search bar to a new line */
}

// .navbar-collapse {
//     display: none; /* Hide IconsGroup */
// }
.iconsGroup{
    order:2;
    display: flex !important;
   
}
.offcanvas {
  display: block; // Show offcanvas menu
}
  }
  
    /* For extra-large screens, 1400px and above */
    @media (min-width: 768px) {
      .primaryNavbar-toggle {
        display: block; /* Show toggle button */
        margin-right: 5px;
        justify-content: space-between;
    }

    .navbar-brand {
        margin-bottom: 10px; /* Add space below the logo */
    }
    .primaryNavbarLogo {
        order: 1;
        flex:1px;
        
    }

    .searchBar {
        order: 3;
        width: 100%; /* Make search bar take full width */
        margin-top: 10px; /* Add some spacing */
        flex-basis: 100%; /* Push the search bar to a new line */
    }

    // .navbar-collapse {
    //     display: none; /* Hide IconsGroup */
    // }
    .iconsGroup{
        order:2;
        display: flex !important;
       
    }
    .offcanvas {
      display: block; // Show offcanvas menu
  }
}

@media (min-width: 992px) {
 

  .primaryNavbar-container {
    display: flex; /* Flexbox for child elements */
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Space between elements */
    width: 100%; /* Full width */
  }

  .primaryNavbar-class {
    order: 1; /* First in order */
    display: flex; /* Ensure flex display */
    align-items: center; /* Center logo vertically */
    margin-right: 10px; /* Add some spacing after the logo */
  }

  .primaryNavbar-toggle {
    display: none; /* Hide toggle button on larger screens */
  }

  .primaryNavbar-pharma-logo {
    order: 1; /* First in order */
    margin-right: 10px; /* Add some spacing after the logo */
  }

  .searchBar {
    order: 2; /* Second in order */
    flex: 1; /* Grow to fill available space */
    margin: 0 15px; /* Add margins on the sides */
    display: flex; /* Use flexbox for search input and button */
    align-items: center; /* Center items vertically within the bar */
    padding: 10px;
    border-radius: 4px; /* Rounded corners for the search bar */
    position: relative; /* Relative positioning for absolute positioning within */
  }

  .locationDropdown {
    margin-right: 10px; /* Add margin to the right of the dropdown */
    display: flex; /* Keep it inline with the search input */
    align-items: center; /* Center dropdown items vertically */
  }

 

  .iconsGroup {
    order: 3; /* Third in order */
    display: flex !important;
    align-items: center; /* Center icons vertically */
    justify-content: flex+-end; /* Push icons to the right */

  }
  
}

@media (min-width: 1200px) { // Extra large screens
  .primaryNavbar{
     position: fixed; /* Makes the navbar fixed */
  top: 0; /* Aligns it to the top of the page */
  left: 0; /* Aligns it to the left of the page */
  right: 0; /* Stretches it to the right */
  z-index: 1000; /* High z-index to keep it on top of other content */
  background-color: #f8f9fa; /* Background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  padding: 10px 20px; /* Adds some padding around the navbar content */
  }
  .primaryNavbar-container {
    display: flex !important;
   
  }
}


@media (max-width: 575.98px) {
 

  
 

  .primaryNavbar-pharma-logo {
    order: 1; /* First in order */
    margin-right: 10px; /* Add some spacing after the logo */
  }

 
  
}

@media (min-width: 1400px) { // Extra extra large screens
  .primaryNavbar{
    position: fixed; /* Makes the navbar fixed */
  top: 0; /* Aligns it to the top of the page */
  left: 0; /* Aligns it to the left of the page */
  right: 0; /* Stretches it to the right */
  z-index: 1000; /* High z-index to keep it on top of other content */
  background-color: #f8f9fa; /* Background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  padding: 10px 20px; /* Adds some padding around the navbar content */
  
  .primaryNavbar-container {
    display: flex !important;
    
  }
}
}
}
.iconsGroup {
  display: flex !important;
  align-items: center;
  gap: 15px;

  @media (max-width: 990px) {
    display: flex !important; // Force display on smaller screens
    justify-content: center;
  }

  .icon {
    font-size: 20px;
    color: #333;

    &:hover {
      color: #007bff;
    }
  }
}
.iconsGroup {
  position: relative;

  .iconsGroupProfileIcon {
    cursor: pointer;
   
  }

  .profile-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;

    .profile-menu-item {
      padding: 10px 15px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
