.bill-details-card {
    background-color: #fff;
    padding: 5px;
    border-radius: 8px;
    color: var(--grey);
    margin-top: 10px;
  
    .bill-apply-coupon-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid var(--white);
      border-radius: 8px;
      padding: 10px 15px;
      color: var(--dark-blue);
      cursor: pointer;
      width: 100%;
      margin-bottom: 20px;
       
    .fa-arrow-right {
      font-size: 18px;
      color:  var(--dark-blue);
    }
     
  
    }
  
    .bill-details {
     
  
      .bill-details-header {
        font-size: 16px;
        font-weight: 600;
        // color: color(--black);
        $black: #000000; // Define a Sass variable
  
        margin-bottom: 10px;
      }
  
      hr {
        border: 0;
        border-top: 1px solid #e0e0e0;
        margin: 0;
      }
  
      .bill-delivery-note {
        color: #28a745;
        margin-top: 10px;
      }
      &.text-success {
        color: #28a745;
      }
    }
  
    .bill-estimated-payable {
      margin-top: 5px;
      color: var(--grey);
  
  
      .bill-estimated {
   
        // color: var(--black);
        $black: #000000; // Define a Sass variable
  
        margin: 5px 0 0;
      }
      
      
    }
  
    .add-address-btn {
      background-color: var(--dark-blue);
      color: #fff;
      font-weight: 300;
      border-radius: 8px; 
      width: 100%;
      text-align: center;
      cursor: pointer;
      border: none;
      min-height:20px;
  
      &:hover {
        background-color: var(--dark-blue);
      }
    }
  }
  