.profile-section {
  padding: 20px;
  background-color: whitesmoke;

  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    color: #555;
    background-color: var(--white);

    .breadcrumb-icon {
      color: #333;
      margin-right: 8px;
    }

    .breadcrumb-text {
      margin: 0 5px;
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
        color: #007bff; // Hover color
      }
    }

    .breadcrumb-separator {
      margin: 0 5px;
      color: #aaa;
    }
  }
  .profile-container{

    padding:5px;
    border-radius: 10px;
  
    background-color: whitesmoke; 
  
  }
  .profile-person {
    padding: 20px;
    background-color: var(--white);
    border-radius: 10px;

    .text-heading1 {
      padding: 10px !important;
      margin-bottom: 20px;
      text-align: left !important;
      align-self: flex-start; /* If parent is flexbox */
  }
    .form {
      .form-row {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;

        .form-group {
          flex: 1;
          display: flex;
          flex-direction: column;

          label {
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 14px;
          }
          .image-container {
            position: relative;
            display: inline-block;
            width: 200px; /* Adjust to fit the size shown in your image */
            height: 100px;
            border: 1px dashed #ddd; /* Border around the container */
            border-radius: 8px;
            overflow: hidden;
          
            .uploaded-image {
              width:200px;
              height: 100px;
              object-fit: cover; /* Ensures the image fills the container */
              border-radius: 8px;
            }
          
            .close-icon {
              position: absolute;
              top: 5px;
              right: 5px;
              font-size: 16px;
              color: #fff;
              background-color: #f44336; /* Red background for the close icon */
              border-radius: 50%;
              padding: 4px;
              cursor: pointer;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Adds shadow for a floating effect */
            }
          }
          .date-picker{
              width:100%;
              padding:6px;
              border-radius: 7px;
              border: 1px solid #e1e1e1;
            }
            .required-symbol {
              color: red;
              margin-left: 5px;
            }
        

          .input,
          .readOnlyInput {
            width: 100%;
            padding: 8px;
            border-radius: 5px;
            border: 1px solid #ddd;
            font-size: 14px;
            background-color: #f9f9f9;
          }

          .input:focus {
            border-color: #007bff;
            outline: none;
          }
        }
      }

      .action-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .submit-button {
          padding: 7px;
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          background-color: var(--dark-blue);
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #0056b3;
          }
        }
      }
    }
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .profile-person {
      .form-row {
        flex-direction: column;
        gap: 15px;
      }

      .action-buttons {
        .submit-button {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 575.98px) {
  

    .profile-person {
      padding: 15px;

      .text-heading1 {
        font-size: 1.25rem;
      }

      .form-row {
        flex-direction: column;
        gap: 10px;
      }

      .action-buttons {
        justify-content: center;

        .submit-button {
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }
}
