.offer-page-section {
  padding: 20px;
  background-color: whitesmoke;
  
  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 10px ;
    font-size: 16px;
    color: #555;
   
  background-color: var(--white);

  .breadcrumb-icon {
    color: #333;
    margin-right: 8px;
  }

  .breadcrumb-text {
    margin: 0 5px;
    text-decoration: none; // Remove underline
    color: inherit;
&:hover {
  text-decoration: none; // Ensure no underline on hover
 
}
  }

  .breadcrumb-separator {
    margin: 0 5px;
    color: #aaa;
  }
  }
  .add-discount-person-container{
    padding:5px;
    border-radius: 10px;

    background-color: whitesmoke;
  }
  .text-heading1 {
    padding: 10px !important;
    margin-bottom: 20px;
    text-align: left !important;
    align-self: flex-start; /* If parent is flexbox */
}
  .offer-page {
    background-color: var(--white);
    padding: 20px;
    border-radius: 10px;
  }

  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
   
  }

  .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      margin-bottom: 5px;
      font-weight: bold;
    }

    input, select {
      
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;
      
    }

    input[type="datetime-local"] {
      padding: 8px;
    }

    input:focus, select:focus {
      border-color: var(--dark-blue);
    }

   
  }
  .form-row1{
    width:50% !important;
}

  .save-button-container {
    display: flex;
    justify-content: flex-end;

  .submit-button {
    margin-top: 20px;
    background-color: var(--dark-blue);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #083d73;
    }
  }
}
   /* Media Queries for Responsive Design */
   @media (min-width: 1400px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 1200px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 992px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 768px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 576px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    /* Stack fields in a column for small screens */
    @media (max-width: 575.98px) {
    
      .form-row {
        display: flex;
        flex-direction: column;
      }
  
      .form-group {
        margin-bottom: 15px;
      }

      .form-row1 {
        width: 100%;
      }
  
      .submit-button {
        width: 100%;
        margin: 0 auto; /* Center the button on small screens */
      }
    }
  }