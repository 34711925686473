.shop-section {
  padding: 20px;
  background-color: #f0f8ff;
  border-radius: 8px;
  margin: 0px;

  .carousel-section {
    margin-bottom: 40px;
  }

  .section-title {
    font-weight: 700;
    font-style: normal;
    color: #333;
    margin: 10px 0px 20px 20px;
  }

  .carousel-row {
    display: flex;
    justify-content: flex-start; /* Align cards to the left */
    align-items: center;
    margin: 0; /* Remove extra margin to reduce gaps */
  }

  .carousel-col {
    display: flex;
    justify-content: center;
    padding: 0 5px; /* Reduce the padding to reduce gaps between cards */
  }

  /* Carousel Controls */
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: #858282;
    border-radius: 50%;
    width: 30px; /* Smaller control icon */
    height: 30px;
    background-size: 60%; /* Adjust size of arrow inside */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 30px; /* Reduce control width */
    height: 30px; /* Reduce control height */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
}
