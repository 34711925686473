.searchBar {
    display: flex;
    align-items: center;
   
    border-radius: 5px;
    padding: 5px;
    
    width: 100%;
    max-width: 600px;
    position: relative; // To handle dropdown alignment
    .locationWrapper {
        flex: 1;
       
       
        padding: 10px;
      
    }
    .searchInputWrapper {
        flex: 3;
        position: relative;
        background-color: #ffffff; // White background for search bar
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .searchIcon {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: #aaa;
        }

        .searchInput {
            width: 100%;
            padding: 10px 10px 10px 30px; // Adjust padding for the search icon
            border: none;
            outline: none;
            border-radius: 5px 0 0 5px; // Rounded corners on the left
            font-size: 14px;
        }
    }

    .searchButton {
        background-color: #007bff;
        color: white;
        padding: 10px 15px;
        border: none;
        border-radius: 0 5px 5px 0; // Rounded corners on the right
        cursor: pointer;
        font-size: 14px;

        &:hover {
            background-color: #0056b3;
        }
    }

    .searchResults {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: white;
        border: 1px solid #ccc;
        border-top: none;
        z-index: 1000;
        border-radius: 0 0 5px 5px;
        max-height: 300px; // Set max height for scrollable dropdown
        overflow-y: auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        .searchResultItem {
            padding: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #f1f1f1;

            &:hover {
                background-color: #f8f9fa;
            }

            .resultIcon {
                margin-right: 10px;
                color: #007bff;
            }

            .resultText {
                font-size: 14px;
                color: #333;
            }
        }

        .searchNoResults,
        .searchSeeAll {
            text-align: center;
            padding: 10px;
            color: #007bff;
            cursor: pointer;

            &:hover {
                background-color: #f8f9fa;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;

        .locationWrapper {
            margin-bottom: 10px; // Add spacing below location
            text-align: left; // Ensure location is left-aligned
            padding-left: 0; // Remove extra padding for better alignment
          }
        .searchInputWrapper {
            width: 100%;
        }

        
        .searchButton {
            display: none; // Hide search button in mobile view
        }}
}
