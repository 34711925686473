.single-product-details {
  padding: 10px;
  height: auto;

  .breadcrumb {
    color: var(--light-grey);
    margin-bottom: 20px;
  }

  .product-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .left-section {
      width: 69%;
      display: flex;
      border: 1px solid var(--white);
      padding: 20px;
      border-radius: 5px;

      .image-carousel {
        position: relative;
        margin-right: 20px;
        flex: 1;

        img {
          width: 100%;
          height: auto;
          border-radius: 5px;
          object-fit: contain;
        }
      }

      .product-info {
        flex: 2;
        max-height: 500px;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }

        .product-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }

        .product-actions {
          display: flex;
          align-items: center;
          margin-top: 20px;
          gap: 10px;
        
          .add-to-cart,
          .subscription-button {
            background-color: var(--dark-blue);
            color: var(--white);
            padding: 7px 15px;
            border: none;
            border-radius: 5px;
            font-weight: bold;
            cursor: pointer;
            transition: background-color 0.3s ease;
            text-align: center;
            width: 150px; /* Set equal width for both buttons */
          }
        
          // .add-to-cart:hover,
          // .subscription-button:hover {
          //   color: var(--dark-blue);
          //   background-color: var(--white);
          //   border: var(--dark-blue) solid 2px;
          // }
        }
        
        
        .price-info {
          .mrp {
            text-decoration: line-through;
            color: var(--light-grey);
          }
        
          .price {
            font-weight: bold;
            margin: 5px 0;
            color: var(--dark-blue);
          }
        
          .discount-badge {
            background-color: var(--light-green); // Green color for the discount badge
            color: var(--green);
            padding: 3px 6px;
            border-radius: 3px;
            font-size: 12px;
            display: inline-block;
            font-weight: 500;
          }
        
          .inclusive-tax {
            font-size: 12px;
            color: var(--light-grey);
          }
        }
        

        .delivery-pincode-container {
          display: flex;
          align-items: center;
          background-color: var(--sky-blue);
          border-radius: 10px;
          margin: 20px 0;
          padding: 10px 20px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

          .delivery-info {
            font-size: 14px;
            font-weight: 500;
            color: var(--dark-grey);

            .delivery-date {
              font-weight: bold;
              color: var(--dark-blue);
            }
          }
        }

        .features-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #f0f7ff;
          padding: 15px 20px;
          border-radius: 10px;

          .feature {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .feature-icon {
              width: 30px;
              height: 30px;
              margin-bottom: 10px;
            }

            p {
              font-size: 14px;
              color: #333;
              margin: 0;
            }
          }

          .divider {
            width: 1px;
            height: 40px;
            background-color: #e0e0e0;
          }
        }
      }
    }

    .right-section {
      width: 30%;
      margin-left: 20px;

      @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  /* Tabs and Tab Content */
  .tabs {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent tabs from wrapping */
    gap: 10px;
    margin: 20px 0;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      height: 6px; /* Adjust scrollbar height */
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--dark-grey);
      border-radius: 3px;
    }

    .tab-button {
      flex: 0 0 auto; /* Prevent the buttons from shrinking */
      padding: 10px 15px;
      font-size: 14px;
      font-weight: 500;
      color: var(--dark-grey);
      background-color: var(--white);
      border: 1px solid var(--border-line);
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      white-space: nowrap;
      transition: all 0.3s ease;

      &:hover {
        background-color: var(--white);
        color: var(--dark-blue);
        border:2px solid var(--dark-blue);
      }

      &.active {
        background-color: var(--dark-blue);
        color: var(--white);
        border-color: var(--dark-blue);
      }
    }
  }

  .tab-content {
    margin-top: 20px;
    padding: 20px;
    background-color: var(--pale-grey);
    border: 1px solid var(--border-line);
    border-radius: 5px;

    h5 {
      font-size: 16px;
      font-weight: bold;
      color: var(--dark-grey);
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      color: var(--grey);
      line-height: 1.5;
    }
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
    .product-content {
      flex-direction: column;

      .left-section {
        width: 100%;
        flex-direction: column; /* Stack image and product info */

        .image-carousel {
          margin-right: 0;
        }
        .feature {
          width: 100%; // Full width for each feature
          flex-direction: row; // Align icon and text side by side
          justify-content: flex-start; // Align text and icon to the start
          margin-bottom: 10px;
    
          .feature-icon {
            margin-right: 10px; // Space between icon and text
            margin-bottom: 0; // Remove bottom margin for side-by-side layout
          }
    
          p {
            text-align: center; // Align text to the left
          }
        }

        .product-info {
          width: 100%;
          margin-top: 20px; /* Add spacing between image and info */
        }
      }

      .right-section {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
      }
    }

    .tabs {
      /* Adjust tab padding for smaller screens */
      .tab-button {
        padding: 8px 12px;
        font-size: 13px;
      }
    }
  }
}
